// Custom Language Composition function

/**
 * Helpers
 */
 const _replace = (str: any, phs: any) => Object
 .getOwnPropertyNames(phs)
 // eslint-disable-next-line require-unicode-regexp
 .reduce((a, n) => a.replace(new RegExp(`:${n}`, 'g'), phs[n]), str)
 
 // Matches strings that start with a set of numbers enclosed in curly brackets.
 // match() will return null if the string doesn't match.
 // match[1] is the comma separated list of numbers in the set.
 // match[3] is the remainder string for printing.
 // eslint-disable-next-line
 const ChoiceSetRe = /^\s*\{(-?\d+(,\s*-?\d+)*|Inf|\*)\}\s*(.*)$/
 
 // Matches strings that start with an interval.
 // An interval is two comma separated numbers enclosed with either [, ] or ( before them,
 // and [, ] or ) after them. ] or ( at the start means an open set on that side, i.e. the
 // first element is excluded. [ or ) at the end means the same there.
 // The first and second number can also be -Inf and Inf, respectively, and both can be *(same result).
 // match[1] is the opening bracket.
 // match[2] is the first number.
 // match[3] is the second number.
 // match[4] is the closing bracket.
 // match[5] is the remainder string for printing.
 // eslint-disable-next-line
 const ChoiceIntervalRe = /^\s*([[\](])(\*|-Inf|-?\d+),\s*(\*|Inf|-?\d+)([[\])])\s*(.*)$/ // match is null if not matched. match
 
 const shownWarnings: any = {}
 const showWarning = (key: any) => {
   if (shownWarnings[key]) return
   shownWarnings[key] = true
   console.warn('No translation found for', key)
 }
 
 // eslint-disable-next-line
 const trGetString = (lang: any, key: any) => {
   try {
     const _key = key.split('.')
     const ret = _key.reduce((a: any, k: any) => a[k], lang)
     if (typeof ret !== 'string') return false
     return ret
   } catch (e) {
     console.log(e)
     return false
   }
 }
 
 // eslint-disable-next-line
 const trValidateRange = (str: any, count: any) => {
   let match = str.match(ChoiceSetRe)
   if (match) {
     // match[1] is a comma separated list of accepted counts, OR Inf, OR *.
     if (match[1] === 'Inf' || match[1] === '*') return match[3]
     // eslint-disable-next-line require-unicode-regexp
     const numbers = match[1].split(/,\s*/).map((n: any) => parseInt(n, 10)) // parseInt can take multiple arguments
     if (numbers.reduce((a: any, n: any) => a || n === count, false)) {
       return match[3]
     }
     return false
   }
   match = str.match(ChoiceIntervalRe)
   if (!match) return str
 
   if (match[2] !== '-Inf' && match[2] !== '*') { // Check that the count is within the lower bound
     const lower = parseInt(match[2], 10)
     if (count < lower) return false
     if (match[1] !== '[' && count === lower) return false
   }
 
   if (match[3] !== 'Inf' && match[3] !== '*') { // Check that the count is within the upper bound
     const upper = parseInt(match[3], 10)
     if (count > upper) return false
     if (match[4] !== ']' && count === upper) return false
   }
 
   return match[5]
 }
 
 /**
  * Defaults (fetch from VUEX later)
  */
 const locale = 'no' // language
 
 /**
  * Main translation functions
  */
 
 export const trans = (_key: any, params = {}) => {
   const i = _key.indexOf('.')
   // if (i === -1) return key
   const cat = _key.slice(0, i)
   const key = _key.slice(i + 1)
 
   if (i === -1) return key // moved
 
   try {
     const langfile = require(`../lang/${locale}/${cat}.js`) // eslint-disable-line
     const str = trGetString(langfile, key)
     return _replace(str, params)
   } catch (e) {
     showWarning(_key)
     return key
   }
 }
 
 export const transChoice = (key: any, count: any, params = {}) => {
   const _params = {
     count,
     ...params
   }
   const str = trans(key, _params)
   if (str === key) return str
 
   const choices = str.split('|')
   // eslint-disable-next-line guard-for-in, no-restricted-syntax
   for (const cId in choices) {
     const choice = trValidateRange(choices[cId], count)
     if (choice) return choice
   }
 
   return str
 }
